import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
//
import GraphQLErrorList from '../components/graphql-error-list'
import { Container, Row, Column } from '../components/structure'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content'
import { QuoteCTACard, TestimonialCard } from '../components/elements/cards'
import Sidebar from '../components/elements/sidebar'
//
// const PageHero = loadable(() => import('../components/page-hero'))

export const query = graphql`
  query SuccessPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(slug: { current: { regex: "/(drafts.|)about/" } }) {
      id
      title
      mainImage {
        asset {
          _id
          metadata {
            lqip
            dimensions {
              aspectRatio
            }
          }
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        alt
      }
      _rawBody
    }
  }
`

const SuccessPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = data && data.site
  // const { mainImage, title, _rawBody } = data && data.page

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      <Container>
        <Row cols={3}>
          <Column colSpan={2}>
            <h1 className={`text-5xl font-display font-bold text-brand-mainalpha70`}>
              Great success!
            </h1>
            <p>Your request was sent. We will get back to you as soon as we can.</p>
          </Column>
          <Sidebar>
            <QuoteCTACard />
            <TestimonialCard />
          </Sidebar>
        </Row>
      </Container>
    </Layout>
  )
}

export default SuccessPage
